import { defineStore, acceptHMRUpdate } from "pinia";

export const useSaleFlow = defineStore("SaleFlow", {
  state: () => ({
    retail_chain: {},
    pos: {},
    category: {},
    provider: {},
  }),

  actions: {
    setInitialValues(retail_chain: any, pos: any) {
      this.retail_chain = retail_chain;
      this.pos = pos;
    },
    setCategory(category: any) {
      this.category = category;
    },
    setProvider(provider: any) {
      this.provider = provider;
    },
  },
  persist: {
    storage: persistedState.sessionStorage,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
